import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../shared/services/authentication/authentication.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {EnvironmentService} from '../shared/services/environment.service';

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitComponent implements OnInit, AfterViewInit {

  constructor(private router: Router, private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private oauthService: OAuthService,
              private environmentService: EnvironmentService) {
    console.log('Initializing WaitComponent');
  }

  ngOnInit(): void {
    if (this.environmentService.getAuthentication() === 'none') {
      this.router.navigate(['registration']);
    } else if (this.environmentService.getAuthentication() === 'backend') {
      this.route.queryParams.subscribe(routeParams => {
          this.authenticationService.afterLogin(routeParams.code, routeParams.state)
            .then(value => {
              if (value) {
                this.router.navigate(['registration']);
              } else {
                console.error('problem with afterLogin');
              }
            });
        }
      );
    } else {
      this.oauthService.events
        .subscribe({
          next: value => {
            if (value.type === 'token_received') {
              this.authenticationService.afterLogin(null, null);
              this.router.navigate(['registration']);
            }
          },
          error: err => {
            console.error('problem with oauthService.events: ' + err);
          },
          complete: () => {
          }
        });
    }
  }

  ngAfterViewInit(): void {
  }
}
