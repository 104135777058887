import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RegistrationService} from "../shared/services/registration.service";
import {ErrorMessageService} from "../shared/services/error-message.service";
import {AuthenticationService} from "../shared/services/authentication/authentication.service";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;
  userName: string = '';
  message: string = '';

  constructor(private registrationService: RegistrationService,
              private changeDetector: ChangeDetectorRef,
              public authenticationService: AuthenticationService,
              private errorMessageService: ErrorMessageService) {
    console.log('Initializing ErrorComponent');
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userName = (this.authenticationService.user != null)
      ? this.authenticationService.user.getUserName()
      : 'Bezoeker';
    this.message = this.errorMessageService.errorMessage;
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
    this.changeDetector.detectChanges();

    this.authenticationService.logoffAfterDelay()
  }
}
