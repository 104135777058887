import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {RegistrationComponent} from './registration/registration.component';
import {SuccessComponent} from './success/success.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import localeNLBE from '@angular/common/locales/nl-BE';
import {SpinnerComponent} from './shared/spinner/spinner.component';
import {SpinnerModule} from './shared/spinner/spinner.module';
import {OAuthModule} from 'angular-oauth2-oidc';
import {AuthenticationService} from './shared/services/authentication/authentication.service';
import {authenticatorFactory} from './shared/factories/authenticator-factory.factory';
import {Environment} from './shared/models/environment';
import {AuthenticationAllowAllService} from './shared/services/authentication/authentication-allow-all.service';
import {AuthenticationWithOpenIDConnectService} from './shared/services/authentication/authentication-with-open-idconnect.service';
import {environment} from 'src/environments/environment';
import {ErrorComponent} from './error/error.component';
import {ErrorMessageService} from "./shared/services/error-message.service";
import {AuthInterceptor} from './shared/services/authentication/authinterceptor.service';
import {WaitComponent} from './wait/wait.component';
import {CheckboxModule} from "primeng/checkbox";
import {ApplicationConfigurationService} from "./shared/services/application-configuration.service";
import {CookieService} from "ngx-cookie-service";
import {AuthenticationWithBackendService} from "./shared/services/authentication/authentication-with-backend.service";

registerLocaleData(localeNLBE);
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    RegistrationComponent,
    SuccessComponent,
    ErrorComponent,
    WaitComponent
  ],
  imports: [
    NgxMaskModule.forRoot(options),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['http://localhost:8083/'], // TODO should be in configuration
        sendAccessToken: true
      }
    }),
    CheckboxModule,
    CheckboxModule,
    CheckboxModule,
  ],
  providers: [
    {
      provide: AuthenticationService,
      useFactory: authenticatorFactory,
      deps: [Environment, AuthenticationAllowAllService, AuthenticationWithOpenIDConnectService, AuthenticationWithBackendService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: Environment, useValue: environment},
    {provide: LOCALE_ID, useValue: 'nl-BE'},
    DatePipe,
    AuthenticationAllowAllService,
    AuthenticationWithOpenIDConnectService,
    AuthenticationWithBackendService,
    CookieService,
    ErrorMessageService,
    ApplicationConfigurationService
  ],
  bootstrap: [
    AppComponent,
    SpinnerComponent
  ]
})
export class AppModule {
}
