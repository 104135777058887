import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from "./environment.service";
import {ApplicationConfigurationParameter} from "../models/enums/application-configuration-parameter.enum";

@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigurationService {

  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) { }

  public  getApplicationConfigurationValue(key: ApplicationConfigurationParameter): Promise<any> {
    return this.httpClient.get<any>(this.environmentService.getRegistrationServiceBaseUrl('configuration/getApplicationConfigurationValue'),
      {
        params: {
          key: key
        }
      })
      .toPromise();
  }
}
