import {AuthenticationAllowAllService} from '../services/authentication/authentication-allow-all.service';
import {AuthenticationWithOpenIDConnectService} from '../services/authentication/authentication-with-open-idconnect.service';
import {Environment} from '../models/environment';
import {AuthenticationWithBackendService} from '../services/authentication/authentication-with-backend.service';
import {throwError} from 'rxjs';

export function authenticatorFactory(environment: Environment,
                                     allowAll: AuthenticationAllowAllService,
                                     openIdConnect: AuthenticationWithOpenIDConnectService,
                                     backend: AuthenticationWithBackendService) {

  switch (environment.authentication) {
    case 'none':
      console.debug('Allow All Authentication Server created');
      return allowAll;
    case 'backend':
      return backend;
    case 'openidconnect':
      return openIdConnect;
    default:
      throwError('incorrect authentication env variable: ' + environment.authentication);

  }
}
