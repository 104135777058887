<header style="height: 45px;">
    <div id="95691c496fb2cb8ca4267b505a0e2d666988c7d1"
         class="iwgh2 iw2-retina-disabled iw2-no-mobile  iw2-mobile-supported iwgh2-redesign  iw2-responsive iwgh2-non-sticky iwgh2-default-margin iw2-js iw2-desktop-mode-active"
         aria-live="off" lang="nl">
        <div class="iwgh2-bar">
            <div class="iw2-layout iw2-default-breakpoint">
                <div class="iw2-wrapper iw2-default-margin">
                    <div class="iw2-inner-wrapper">
                        <div class="iwgh2-navigation">
                            <a class="iw2-branded clickable" target="_self">
                                <div class="iwgh2-branding-logo"></div>
                                <span>Vlaanderen</span>
                            </a>
                            <div class="iw2-menu iw2-menu-no-items" data-show-menu-help="Toon navigatie menu"
                                 data-hide-menu-help="Verberg navigatie menu">
                                <div class="iwgh2-navigation-menu-toggle-wrapper">
                                    <div class="iwgh2-navigation-menu-toggle-animation-wrapper" style="">
                                        <a class="iwgh2-navigation-menu-site clickable">Het Facilitair Bedrijf</a>
                                        <a class="iwgh2-navigation-menu-breadcrumb" style="display:none;" href="javascript:"></a>
                                        <span class="iwgh2-navigation-menu-toggle iw2-hidden"><span
                                                class="iw2-visually-hidden iw2-help-text"> Toon navigatie menu </span></span>
                                    </div>
                                </div>
                                <div class="iwgh2-flyout-wrapper iw2-window-opened" style="display: none; left: 0;" tabindex="-1"
                                     id="4a54fd68-eb36-6d1f-db2b-d4d6517c1e57">
                                    <div class="iwgh2-search iw2-hidden" role="search">
                                        <form method="POST" action="" novalidate="novalidate">
                                            <div class="iwgh2-form-label">
                                                <label for="iwgh2-search-field">Zoeken</label>
                                            </div>
                                            <div class="iwgh2-form-textfield">
                                                <input id="iwgh2-search-field" type="search" class="iwgh2-search-field iwgh2-form-text"
                                                       name="query">
                                            </div>
                                            <div class="iwgh2-form-actions">
                                                <div class="iwgh2-form-action">
                                                    <div class="iwgh2-form-button">
                                                        <input type="submit" class="iwgh2-form-submit" name="op" value="">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <ul class="iw2-menu-items" tabindex="-1"></ul>
                                </div>
                            </div>
                        </div>
                        <div class="iwgh2-extensions">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div style="height: 100%;" class="page">
    <div id="main" style="height: 100%;" itemprop="mainContentOfPage" role="main" tabindex="-1" class="main-content">
        <router-outlet></router-outlet>
    </div>
    <app-spinner style="display: none;"></app-spinner>
</div>


