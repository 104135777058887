import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WelcomeComponent} from './welcome/welcome.component';
import {RegistrationComponent} from './registration/registration.component';
import {SuccessComponent} from './success/success.component';
import {AuthenticationGuard} from './shared/guards/authentication.guard';
import {ErrorComponent} from './error/error.component';
import {WaitComponent} from './wait/wait.component';

const routes: Routes = [
    // {path: '', component: WelcomeComponent},
    { path: '', pathMatch: 'full', redirectTo: 'welcome' },
    { path: 'welcome', component: WelcomeComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'success', component: SuccessComponent },
    { path: 'wait', component: WaitComponent },

    // callbacks declared to VO ACM/IDM
    { path: 'oidc/out', redirectTo: 'welcome' },
    { path: 'oidc', redirectTo: 'wait' },

    {
        path: '',
        canActivate: [AuthenticationGuard],
        children: [
            { path: 'registration', component: RegistrationComponent },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
