import {Deserializable} from './deserializable.model';
import {MeetingStatus} from "./enums/meeting-status.enum";
import {VoEmployeeInformation} from "./vo-employee-information.model";

export class MeetingInformation implements Deserializable {
  public meetingUUID: string;
  public startTime: Date;
  public endTime: Date;
  public building: string;
  public location: string;
  public topic: string;
  public status: MeetingStatus;
  public organiserInformation: VoEmployeeInformation;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
