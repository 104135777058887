export class ACMUser {

  constructor(readonly authIdentifier: string,
              readonly firstName: string,
              readonly lastName: string) {
  }

  public getUserName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
