export const environment = {
  production: false,
  authentication: 'backend',
  registrationServiceBaseUrl: 'https://api.bezoekersregistratietest.vlaanderen.be/registratie/',
  automaticLogoffDelay: 300,
  environmentAuthConfig: {

    // Url of the Identity Provider
    issuer: 'https://authenticatie-ti.vlaanderen.be/op',

    // URL of the SPA to redirect the user to after login
    redirectUri: 'https://bezoekersregistratietest.vlaanderen.be/registratie/oidc',
    postLogoutRedirectUri: 'https://bezoekersregistratietest.vlaanderen.be/registratie/oidc/out',

    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: '',
    dummyClientSecret: '',

    // set the scope for the permissions the client should request
    scope: '',
    responseType: 'code',

    showDebugInformation: true,
  }
};
