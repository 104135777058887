import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {Environment} from "./shared/models/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'frontend-registratie';
  isLoading: boolean = false;
  userName: string = '';

  constructor(private router: Router, private oauthService: OAuthService, private environment: Environment) {
    console.log('Initializing AppComponent');
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.configureOauth();
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
  }

  private configureOauth() {
    console.debug("-- configureOauth --");
    if (this.environment.authentication === 'openidconnect') {
      this.oauthService.configure(this.environment.environmentAuthConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.loadDiscoveryDocumentAndTryLogin()
        .then(
          b => {
            console.debug("-- #logged in --");
            //this.loginError = null;
          })
        .catch(event => {
          console.debug("load catch ");
          //this.loginError = event.error;
        });
    }
  }
}
