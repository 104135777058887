import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {ACMUser} from '../../models/acmuser';
import {Observable, of, throwError} from 'rxjs';
import {Environment} from '../../models/environment';
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationWithOpenIDConnectService extends AuthenticationService {
  public loginError: string;

  private loggedIn: boolean;

  public preservedState: string;

  constructor(private oauthService: OAuthService, private environment: Environment, private cookieService: CookieService) {
    super();
  }

  login(state: string): Observable<string> {
    console.log("#login: start, state: "+state);
    this.loggedIn = false;
    this.oauthService.initCodeFlow(state, {});
    return of('oidc callback is next');
  }

  afterLogin(code: string, state?: string) {
    console.log('#afterLogin: start');
    if (this.oauthService.hasValidIdToken()) {
      console.log('has valid idToken');
    } else {
      console.log('has no valid idToken');
    }
    console.debug('#afterLogin end');

    //this.oauthService.getIdentityClaims();
    //return of('unkown condition during login');// this.oauthService.state??
    if (this.oauthService.state !== null){
      this.preservedState = this.oauthService.state;
    }
    console.log('#afterLogin: end, state:  ' + this.preservedState);
  }

  logoff(): Promise<string> {
    this.oauthService.logOut();
    this.loggedIn = false;
    return Promise.resolve('OpenIDConnect logout');
  }

  isLoggedIn() {
    // this.loggedIn
    // TODO fix this, during callback invocation, user is not logged in yet?
    return true;
  }

  getUser(): Observable<ACMUser> {
    const claims: any = this.oauthService.getIdentityClaims();
    console.log('claims: ' + JSON.stringify(claims));

    if (claims != null) {
      this.user = new ACMUser(
        claims.sub,
        claims.given_name,
        claims.family_name,
      );

      return of(this.user);
    } else {
      this.user = null;
      return throwError('No user information available');
    }
  }

  getToken(): string {
    return this.oauthService.getIdToken();
  }

  getPreservedState(): string {
    return this.preservedState;
  }

  logoffAfterDelay() {
    setTimeout(() => {
      this.logoff();
    }, this.environment.automaticLogoffDelay * 1000);
  }
}
