import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication/authentication.service';
import {EnvironmentService} from "./environment.service";

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  invitationLink: string;

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService) {
    console.log('RegistrationService constructor: ' + authenticationService.getPreservedState());
  }
}
