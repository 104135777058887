import {Deserializable} from './deserializable.model';

export class SavedRegistrationInformation implements Deserializable {
    public email: string;
    public cellphone: string;
    public company: string;
    public saved: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
