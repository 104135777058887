<div *ngIf="isLoading" class="page" style="width: 100%; height: 100%; display:flex; justify-content:center; align-items:center; text-align: center">
  <div class="layout layout--wide">
    <h1 class="h1">Aan het laden</h1>
    <div class="grid">
      <div class="col--12-12">
        <div class="loader"><span class="u-visually-hidden">Aan het laden</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoading">

  <div class="functional-header functional-header--has-actions" role="banner" id="functional-header">
    <div class="layout layout--wide">
      <div class="functional-header__actions">
        <ul>
          <li class="functional-header__action"><span>{{userName}}</span>
          </li>
          <li class="functional-header__action"><a class="clickable" (click)="authenticationService.logoff()">Uitloggen</a>
          </li>
        </ul>
      </div>
      <div class="functional-header__content">
        <!--<a class="functional-header__title" href="overzicht.html">Lipsum Vlaanderen</a>-->
      </div>
    </div>
  </div>
  <div class="layout layout--wide">
    <div class="region">
      <div class="grid">
        <div class="col--1-1">
          <div class="progress-bar u-spacer" id="progressbar">
            <div class="progress-bar__step">
              <a class="progress-bar__bullet" data-placement="top" data-toggle="tooltip"></a>
            </div>
            <div class="progress-bar__step progress-bar__step--active">
              <div class="progress-bar__bullet progress-bar__bullet--current">
                <div class="tooltip tooltip--top tooltip--static">
                  <div class="tooltip__content">Contactgegevens</div>
                  <div class="tooltip__arrow"></div>
                </div>
              </div>
            </div>
            <div class="progress-bar__step">
              <a class="progress-bar__bullet" data-placement="top" data-toggle="tooltip"></a>
            </div>
          </div>

          <div class="u-spacer">
            <h2 class="h2">Vul je contactgegevens in</h2>
            <p *ngIf="errorMessage" class="u-spacer" style="color: #db3434;">{{errorMessage}}</p>
          </div>

          <div class="alert alert--cta" id="alert">
            <div class="alert__content">
              <div class="alert__message">
                <p>
                  Op de dag van je bezoek ontvang je op je gsm-nummer logingegevens om gebruik te maken van ons wifinetwerk, als je deze hebt opgegeven.<br>
                </p>
              </div>
            </div>
          </div>

          <form [formGroup]="registrationForm" autocomplete="off" class="form" action="">
            <dl class="properties">

              <dt class="properties__label properties__label--has-input">Naam</dt>
              <dd class="properties__data">
                <div class="dynamic-label js-dynamic-label">
                  <div class="dynamic-label__label">
                    <span class="js-dynamic-label__value">{{userName}}</span>
                  </div>
                </div>
              </dd>

              <dt class="properties__label properties__label--has-input required">Organisatie/bedrijf</dt>
              <dd class="properties__data">
                <div id="company-input-field" class="dynamic-label js-dynamic-label dynamic-label--active">
                  <div class="dynamic-label__input">
                    <input class="input-field keyboard-input input-field--block js-dynamic-label__field" [ngClass]="(company.dirty && company.invalid)? 'input-field--error': null" type="text" placeholder="Organisatie/Bedrijf" formControlName="company"/>
                    <div *ngIf="company.dirty && company.invalid" class="input-error">
                      <div *ngIf="company.errors">
                        Gelieve een geldig bedrijf in te vullen
                      </div>
                    </div>
                  </div>
                </div>
              </dd>

              <dt class="properties__label properties__label--has-input required">E-mailadres</dt>
              <dd class="properties__data">
                <div id="email-input-field" class="dynamic-label js-dynamic-label dynamic-label--active">
                  <div class="dynamic-label__input">
                    <input class="input-field keyboard-input input-field--block js-dynamic-label__field" [ngClass]="(email.dirty && email.invalid)? 'input-field--error': null" type="text" placeholder="voorbeeld@email.com" formControlName="email"/>
                    <div *ngIf="email.dirty && email.invalid" class="input-error">
                      <div *ngIf="email.errors">
                        Gelieve een geldig e-mail adres in te vullen
                      </div>
                    </div>
                  </div>
                </div>
              </dd>

              <dt class="properties__label properties__label--has-input">Gsm-nummer</dt>
              <dd class="properties__data">
                <div id="cellphone-input-field" class="dynamic-label js-dynamic-label dynamic-label--active">
                  <div class="dynamic-label__input">
                    <input class="input-field keyboard-input input-field--block js-dynamic-label__field" [ngClass]="(cellphone.dirty && cellphone.invalid)? 'input-field--error': null" type="text" [dropSpecialCharacters]="false" placeholder="+32 000 00 00 00" mask="+00 000 00 00 00" [validation]="true" formControlName="cellphone"/>
                    <div *ngIf="cellphone.dirty && cellphone.invalid" class="input-error">
                      <div *ngIf="cellphone.errors">
                        Gelieve een geldig gsm-nummer in te vullen
                      </div>
                    </div>
                  </div>
                </div>
              </dd>

              <dt class="properties__label properties__label--has-input"><p>Gegevens onthouden voor toekomstige registratie <sup>1</sup></p>
              </dt>
              <dd class="properties__data">
                <p-checkbox class="checkbox--block" id="saved" formControlName="saved" binary="true"></p-checkbox>
              </dd>
            </dl>

            <div class="u-spacer">
              <p><sup>1</sup> Indien je ervoor kiest om deze gegevens te onthouden voor toekomstig gebruik, worden ze {{ monthsBeforeExpire + (monthsBeforeExpire > 1 ? ' maanden' : ' maand') }} maanden onthouden. Indien deze vergadering later dan 3 maanden in de toekomst valt, worden deze gegevens onthouden tot en met de dag van de vergadering.</p>
              <br><p>Alle persoonsgegevens worden verwerkt overeenkomstig de Europese Verordering 2016/679 (EU) inzake de bescherming van de persoonsgegevens (AVG) en de Belgische reglementering inzake de bescherming van de persoonlijke levenssfeer in verband met de verwerkingen van persoonsgegevens.</p>
            </div>

            <div class="navigation-button-wrapper">
              <button (click)="onSubmit()" [disabled]="registrationForm.pristine || registrationForm.invalid" [ngClass]="registrationForm.pristine || registrationForm.invalid ? 'button--disabled' : null" type="button" class="button wizard__cta" data-wizard-next="" id="button">Volgende</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>

</div>
