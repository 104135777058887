import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../shared/services/authentication/authentication.service';
import {RegistrationService} from '../shared/services/registration.service';
import {of} from 'rxjs/internal/observable/of';
import {ErrorMessageService} from '../shared/services/error-message.service';
import {InvitationService} from '../shared/services/invitation.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;
  invitationLink: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private authenticationService: AuthenticationService,
              private errorMessageService: ErrorMessageService,
              private registrationService: RegistrationService,
              private invitationService: InvitationService) {
    this.fetchLink();
  }

  fetchLink() {
    console.info('@@ link parameter fetchLink');

    of(this.route.snapshot.queryParams).subscribe(params => {
        this.invitationLink = params.invitationlink;
        this.invitationService.invitationLink = params.invitationlink;

      },
      err => {
        console.error('link parameter problem', err);
        this.errorMessageService.changeErrorMessage('Geen invitatielink gevonden om de vergadering op te zoeken.');
        this.router.navigate(['error']);
      }
    );

    console.info('@@ link parameter fetchLink end');
  }

  ngOnInit(): void {
    this.isLoading = true;
    // this.fetchLink();
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
    this.changeDetector.detectChanges();
    this.login();
  }

  login() {
    this.authenticationService.login(this.invitationService.invitationLink);
  }

  logout() {
    this.authenticationService.logoff();
  }
}
