import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {SpinnerComponent} from './spinner.component';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private overlayRef: OverlayRef = null;
    private isShowing = false;

    constructor(private overlay: Overlay) {

    }

    public show(message = '') {
        if (this.isShowing === false) {
            this.isShowing = true;
            if (!this.overlayRef) {
                this.overlayRef = this.overlay.create();
            }
            const spinnerOverlayPortal = new ComponentPortal(SpinnerComponent);
            const component = this.overlayRef.attach(spinnerOverlayPortal);
            component.instance.message = message;
        }
    }

    public hide() {
        if (!!this.overlayRef) {
            this.overlayRef.detach();
            this.isShowing = false;
        }
    }
}
