import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SpinnerComponent} from './spinner.component';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule
    ],
    declarations: [SpinnerComponent],
    exports: [SpinnerComponent]
})
export class SpinnerModule {
}
