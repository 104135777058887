import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RegistrationInformation} from '../models/registration-information.model';
import {AuthenticationService} from './authentication/authentication.service';
import {EnvironmentService} from './environment.service';
import {SavedRegistrationInformation} from "../models/saved-registration-information.model";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService) {
  }

  public createRegistration(registrationInformation: RegistrationInformation) {
    return this.httpClient.post<any>(
      this.environmentService.getRegistrationServiceBaseUrl('registration/create'), registrationInformation
    );
  }

  public getAndCheckMeetingInformationByInvitationLink(invitationLink: string) {
    return this.httpClient.get<any>(
      this.environmentService.getRegistrationServiceBaseUrl('registration/getAndCheckMeetingInformationByInvitationLink'),
      {params: {invitationLink}})
      .toPromise();
  }


  public getSavedRegistrationInfo() {
    return this.httpClient.get<any>(
      this.environmentService.getRegistrationServiceBaseUrl('registration/getSavedRegistrationInfo'))
      .toPromise()
      .then(result => result ? new SavedRegistrationInformation().deserialize(result) : null);
  }
}
