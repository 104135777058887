<div class="page" style="width: 100%; height: 100%; display:flex; justify-content:center; align-items:center; text-align: center">
  <div class="layout layout--wide">
    <h1 class="h1">Aan het laden</h1>
    <div class="grid">
      <div class="col--12-12">
        <div class="loader"><span class="u-visually-hidden">Aan het laden</span>
        </div>
      </div>
    </div>
  </div>
</div>
