import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  errorMessage: string = '';

  constructor() {
  }

  changeErrorMessage(errorMessage: string) {
    if (errorMessage) {
      this.errorMessage = errorMessage;
    }
  }
}
