<div *ngIf="isLoading" class="page" style="width: 100%; height: 100%; display:flex; justify-content:center; align-items:center; text-align: center">
  <div class="layout layout--wide">
    <h1 class="h1">Aan het laden</h1>
    <div class="grid">
      <div class="col--12-12">
        <div class="loader"><span class="u-visually-hidden">Aan het laden</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoading">
  <div class="functional-header functional-header--has-actions" role="banner" id="functional-header">
    <div class="layout layout--wide">
      <div class="functional-header__actions">
        <ul>
          <li class="functional-header__action"><span>{{userName}}</span>
          </li>
          <li class="functional-header__action"><a class="clickable" (click)="authenticationService.logoff()">Uitloggen</a>
          </li>
        </ul>
      </div>
      <div class="functional-header__content">
        <!--<a class="functional-header__title" href="overzicht.html">Lipsum Vlaanderen</a>-->
      </div>
    </div>
  </div>
  <div class="layout layout--wide">
    <div class="region">
      <div class="grid">
        <div class="col--1-1">

          <h1 class="h1">Bezoekersregistratie</h1>

          <div class="progress-bar u-spacer" id="progressbar">
            <div class="progress-bar__step">
              <a href="#" class="progress-bar__bullet" data-toggle="tooltip" data-placement="top"></a>
            </div>
            <div class="progress-bar__step">
              <a href="#" class="progress-bar__bullet" data-toggle="tooltip" data-placement="top"></a>
            </div>
            <div class="progress-bar__step progress-bar__step--active">
              <div class="progress-bar__bullet progress-bar__bullet--current">
                <div class="tooltip tooltip--top tooltip--static">
                  <div class="tooltip__content">Einde</div>
                  <div class="tooltip__arrow"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="u-table-overflow info-block--timeline">

            <div class="u-table-overflow u-spacer--large">
              <h2 class="h3">Je bent succesvol geregistreerd</h2>
              <div class="alert alert--cta" id="alert">
                <div class="alert__content">
                  <div class="alert__message">
                    <p>We sturen je ten laatste de werkdag voor het starten van je vergadering een e-mail met je persoonlijke aanmeldcode.
                      Op de ochtend van de dag van je vergadering sturen we je via sms de logingegevens voor je wifitoegang.</p>
                  </div>
                </div>
              </div>
            </div>


            <header class="info-block__header">
              <h2 class="info-block__title">Geplande vergadering</h2>
            </header>

            <form class="form col--4-6 col--1-1--s" action="">
              <dl class="properties">

                <dt class="properties__label properties__label--has-input">Datum</dt>
                <dd class="properties__data">
                  <div class="properties__label--has-input">
                    <span class="js-dynamic-label__value">{{meetingInformation.startTime | date: 'dd/MM/yyyy'}}</span>
                  </div>
                </dd>

                <dt class="properties__label properties__label--has-input">Uur</dt>
                <dd class="properties__data">
                  <div class="properties__label--has-input">
                    <span class="js-dynamic-label__value"> Van {{meetingInformation.startTime | date: 'HH:mm'}} tot {{meetingInformation.endTime | date: 'HH:mm'}}</span>
                  </div>
                </dd>

                <dt class="properties__label properties__label--has-input">Locatie</dt>
                <dd class="properties__data">
                  <div class="properties__label--has-input">
                    <span class="js-dynamic-label__value">{{meetingInformation.building + ' ' + meetingInformation.location}}</span>
                  </div>
                </dd>

                <dt class="properties__label properties__label--has-input">Organisator</dt>
                <dd class="properties__data">
                  <div class="properties__label--has-input">
<!--                    <span class="js-dynamic-label__value">{{meetingInformation.organiser}}</span>-->
                    <span class="js-dynamic-label__value">{{meetingInformation.organiserInformation && meetingInformation.organiserInformation.firstName ?
                      meetingInformation.organiserInformation.firstName + ' ' + meetingInformation.organiserInformation.lastName : ''}}</span>
                  </div>
                </dd>

                <dt class="properties__label properties__label--has-input">Onderwerp</dt>
                <dd class="properties__data">
                  <div class="properties__label--has-input">
                    <span class="js-dynamic-label__value">{{meetingInformation.topic}}</span>
                  </div>
                </dd>
              </dl>
            </form>
          </div>

          <div class="u-spacer">
            <span>Alle persoonsgegevens worden verwerkt overeenkomstig de Europese Verordering 2016/679 (EU) inzake de bescherming van de persoonsgegevens (AVG) en de Belgische reglementering inzake de bescherming van de persoonlijke levenssfeer in verband met de verwerkingen van persoonsgegevens.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

