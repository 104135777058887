import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RegistrationService} from '../shared/services/registration.service';
import {Router} from '@angular/router';
import {RegistrationInformation} from '../shared/models/registration-information.model';
import {MeetingInformation} from '../shared/models/meeting-information.model';
import {SpinnerService} from '../shared/spinner/spinner.service';
import {AuthenticationService} from '../shared/services/authentication/authentication.service';
import {ErrorMessageService} from '../shared/services/error-message.service'
import {ACMUser} from '../shared/models/acmuser';
import {InvitationService} from '../shared/services/invitation.service';
import {ApplicationConfigurationParameter} from '../shared/models/enums/application-configuration-parameter.enum';
import {ApplicationConfigurationService} from '../shared/services/application-configuration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit, AfterViewInit {
  @Input() displayError: boolean;

  errorMessage: string;
  isLoading: boolean = false;
  userName: string = '';
  monthsBeforeExpire: number;
  preCellphone1 = '+32';
  preCellphone2 = '+32 000 00 00 00';

  acmUser: ACMUser;
  registrationForm: FormGroup;
  meetingInformation: MeetingInformation;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private invitationService: InvitationService,
    private errorMessageService: ErrorMessageService,
    private registrationService: RegistrationService,
    public authenticationService: AuthenticationService,
    private applicationConfigurationService: ApplicationConfigurationService) {

    this.registrationForm = this.formBuilder.group({
      company: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      cellphone: new FormControl(''),
      saved: new FormControl(false)
    });
  }

  get company() {
    return this.registrationForm.get('company');
  }

  get email() {
    return this.registrationForm.get('email');
  }

  get cellphone() {
    return this.registrationForm.get('cellphone');
  }

  ngOnInit(): void {
    this.applicationConfigurationService.getApplicationConfigurationValue(ApplicationConfigurationParameter.MONTHS_TO_REMEMBER_REGISTRATION_INFO)
      .then(result => this.monthsBeforeExpire = result);

    this.isLoading = true;
    this.errorMessage = '';
    this.initializeAcmUser();
    this.invitationService.invitationLink = this.authenticationService.getPreservedState();

    if (!this.invitationService.invitationLink || this.invitationService.invitationLink === 'undefined') {
      this.redirectToError('Geen invitatielink gevonden om de vergadering op te zoeken.');
    } else {
      this.startChecks();
    }
  }

  ngAfterViewInit(): void {
    // this.isLoading = false;
    this.changeDetector.detectChanges();
  }

  initializeAcmUser() {
    this.authenticationService.getUser().subscribe({
      next: value => {
        this.acmUser = value;
      },
      error: err => {
        console.error('get ACMUser info, error: ' + JSON.stringify(err));
      },
      complete: () => {
        this.userName = this.acmUser.getUserName();
      }
    });
  }

  startChecks() {
    this.registrationService.getAndCheckMeetingInformationByInvitationLink(this.invitationService.invitationLink)
      .then(
        result => {
          this.registrationService.getSavedRegistrationInfo()
            .then(
              (savedRegistrationInformation) => {
                if (savedRegistrationInformation) {
                  savedRegistrationInformation.saved = false;
                  this.registrationForm.setValue(savedRegistrationInformation);
                }
              }
            );

          this.meetingInformation = result;
          this.isLoading = false;
          this.changeDetector.detectChanges();
        },
        error => {
          this.redirectToError(error.error);

        });
  }

  redirectToError(errorMsg: string) {
    this.errorMessageService.changeErrorMessage(errorMsg);
    this.router.navigate(['/error'], {
      queryParamsHandling: 'preserve'
    });
  }

  onSubmit() {
    this.spinnerService.show();
    this.displayError = false;

    const registrationInformation = new RegistrationInformation();

    registrationInformation.meetingUUID = this.meetingInformation.meetingUUID;
    registrationInformation.invitationLink = this.invitationService.invitationLink;
    registrationInformation.email = this.registrationForm.controls.email.value;
    registrationInformation.cellphone = (this.registrationForm.controls.cellphone.value != this.preCellphone1 && this.registrationForm.controls.cellphone.value != this.preCellphone2) ? this.registrationForm.controls.cellphone.value : undefined;
    registrationInformation.company = this.registrationForm.controls.company.value;
    registrationInformation.saved = this.registrationForm.controls.saved.value;
    registrationInformation.wifi = false;

    this.registrationService.createRegistration(registrationInformation).subscribe(
      data => {
        console.log('Finished creating registration');
        this.spinnerService.hide();

        this.router.navigate(['/success'], {
          state: {data: {meetingInformation: this.meetingInformation}},
          queryParamsHandling: 'preserve'
        });
      },
      error => {
        console.log('createRegistration error: ', error);
        this.redirectToError('Je registratie kan niet verwerkt worden.');
        this.spinnerService.hide();
      }
    );
  }
}
