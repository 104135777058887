import {Deserializable} from './deserializable.model';

export class SecurityItem implements Deserializable {

  public expires_in: number;
  public access_token: string;
  public id_token: string;
  public refresh_token: string;

  getTokenExpiresAt(): Date{
    let date = new Date();
    date.setSeconds(date.getSeconds() + this.expires_in);
    return date;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
