import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {ACMUser} from '../../models/acmuser';
import {Observable, of} from 'rxjs';
import {Environment} from '../../models/environment';
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationAllowAllService extends AuthenticationService {

  private preservedState: string;

  constructor(private router: Router, private environment: Environment, private cookieService: CookieService) {
    super();
  }

  login(state: string): Observable<string> {
    this.preservedState = state;
    this.router.navigate(['/oidc']);
    return of('allow all dummy login');
  }

  afterLogin(code: string, state?: string) {

  }

  logoff(): Promise<string> {
    this.router.navigate(['/oidc/out']);
    return Promise.resolve('allow all dummy logout');
  }

  isLoggedIn() {
    return true;
  }

  getUser(): Observable<ACMUser> {
    this.user = this.getBezoeker();
    return of(this.user);
  }

  getToken(): string {
    return '';
  }

  getBezoeker(): ACMUser {
    return new ACMUser(
      'authid1', // claims.sub
      'Test',
      'Bezoeker'
    );
  }

  getFred(): ACMUser {
    return new ACMUser(
      'authid-personid1', // claims.sub
      'Fred',
      'Flinstone'
    );
  }

  getPebbles(): ACMUser {
    return new ACMUser(
      'authid-personid2', // claims.sub
      'Pebbles',
      'Flinstone'
    );
  }

  getBarney(): ACMUser {
    return new ACMUser(
      'authid-personid3', // claims.sub
      'Barney',
      'Rubble'
    );
  }

  getPreservedState() {
    return this.preservedState;
  }

  logoffAfterDelay() {
    setTimeout(() => {
      this.logoff();
    }, this.environment.automaticLogoffDelay * 1000);
  }
}
