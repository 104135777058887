import {Deserializable} from './deserializable.model';

export class RegistrationInformation implements Deserializable {
    public meetingUUID: string;
    public invitationLink: string;
    public email: string;
    public cellphone: string;
    public company: string;
    public wifi: boolean;
    public saved: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
