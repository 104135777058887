<div class="spinner-wrapper">
    <div class="layout layout--wide" style="text-align: center">
        <h1 class="h1">Aan het laden</h1>
        <div class="grid">
            <div class="col--12-12">
                <div class="loader"><span class="u-visually-hidden">Aan het laden</span>
                </div>
            </div>
        </div>
    </div>
</div>
