import {Injectable} from '@angular/core';
import {ACMUser} from '../../models/acmuser';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthenticationService {

  user: ACMUser;

  constructor() { }

  abstract login(state: string): Observable<string>;
  abstract afterLogin(code: string, state?: string);
  abstract logoff(): Promise<string>;
  abstract logoffAfterDelay();
  abstract isLoggedIn(): boolean;
  abstract getToken(): string;

  abstract getUser(): Observable<ACMUser>;
  abstract getPreservedState(): string;
}
