import {Injectable} from '@angular/core';
import {Environment} from '../models/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(private environment: Environment) {
  }

  isAuthenticationInvalid(): boolean {
    return this.environment.authentication === 'none';
  }

  getAuthentication(): string {
    return this.environment.authentication;
  }

  getRegistrationServiceBaseUrl(requestPath: string): string {
    return this.environment.registrationServiceBaseUrl + requestPath;
  }
}
