<div *ngIf="isLoading" class="page" style="width: 100%; height: 100%; display:flex; justify-content:center; align-items:center; text-align: center">
  <div class="layout layout--wide">
    <h1 class="h1">Aan het laden</h1>
    <div class="grid">
      <div class="col--12-12">
        <div class="loader"><span class="u-visually-hidden">Aan het laden</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoading">
  <div class="functional-header functional-header--has-actions" role="banner" id="functional-header">
    <div class="layout layout--wide">
      <div class="functional-header__actions">
        <ul>
          <li class="functional-header__action"><a class="clickable" (click)="login()">Inloggen</a>
          </li>
          <li class="functional-header__action"><a class="clickable" (click)="logout()">Uitloggen</a>
          </li>
        </ul>
      </div>
      <div class="functional-header__content">
        <!--<a class="functional-header__title" href="overzicht.html">Lipsum Vlaanderen</a>-->
      </div>
    </div>
  </div>
  <div class="layout layout--wide">
    <div class="region">
      <div class="grid">
        <div class="col--1-1">

        </div>
      </div>
    </div>
  </div>
</div>

