import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvironmentService} from '../environment.service';
import {AuthenticationService} from './authentication.service';
import {Environment} from '../../models/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private environment: Environment, private environmentService: EnvironmentService,
              private authenticationService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = this.authenticationService.getToken();
    if (!this.environmentService.isAuthenticationInvalid() && idToken && req.url.startsWith(this.environment.registrationServiceBaseUrl)) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + idToken)
      });
      return next.handle(cloned);
    } else if (this.environmentService.isAuthenticationInvalid() && req.url.startsWith(this.environment.registrationServiceBaseUrl)
      && this.authenticationService.user && this.authenticationService.user.getUserName()) {
      const info = this.authenticationService.user.getUserName();
      const cloned = req.clone({
        headers: req.headers.set('Authorization', info)
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
