import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MeetingInformation} from '../shared/models/meeting-information.model';
import {RegistrationService} from '../shared/services/registration.service';
import {AuthenticationService} from '../shared/services/authentication/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;
  userName: string = '';
  meetingInformation: MeetingInformation = new MeetingInformation();

  constructor(private registrationService: RegistrationService,
              private changeDetector: ChangeDetectorRef,
              public authenticationService: AuthenticationService,
              private router: Router) {

    const state = this.router.getCurrentNavigation().extras.state;
    this.meetingInformation = state.data.meetingInformation;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userName = (this.authenticationService.user != null)
      ? this.authenticationService.user.getUserName()
      : 'Bezoeker';
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
    this.changeDetector.detectChanges();
    this.authenticationService.logoffAfterDelay();
  }
}
