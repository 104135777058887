import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../environment.service';
import {SecurityItem} from '../../models/security-item.model';
import {ACMUser} from '../../models/acmuser';
import {CookieService} from 'ngx-cookie-service';
import {JwtHelper} from '../../utils/jwt-helper';
import {Environment} from '../../models/environment';
import {Observable, of, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationWithBackendService extends AuthenticationService {

  private EXPIRES_IN = 'expires_in';
  private TOKEN_EXPIRES_AT = 'id_token_expires_at';
  private ACCESS_TOKEN = 'access_token';
  private ID_TOKEN = 'id_token';
  private REFRESH_TOKEN = 'refresh_token';

  constructor(private oauthService: OAuthService, private cookieService: CookieService, private environment: Environment,
              private http: HttpClient, private environmentService: EnvironmentService) {
    super();
  }

  login(state?: string): Observable<string> {
    this.cookieService.delete('state');
    this.cookieService.set('state', state, 365, '/');
    this.deleteSecurityStorage();
    window.location.href = this.environmentService.getRegistrationServiceBaseUrl('authorizationRegistration');

    return of('Backend loggedin');
  }

  afterLogin(code: string, state?: string): Promise<boolean> {
    return this.http.get(this.environmentService.getRegistrationServiceBaseUrl('authorization/loginRegistration'), {
      params: {
        code: code,
        state: state
      }
    })
      .toPromise()
      .then(result => {
        const securityItem = new SecurityItem().deserialize(result);
        this.putInStorage(securityItem);
        return Promise.resolve(true);
      })
      .catch(() => Promise.reject('No user information available'));
  }

  getPreservedState(): string {
    return this.cookieService.get('state');;
  }

  // @ts-ignore
  logoff(): Promise<string> {
    this.deleteSecurityStorage();
    window.location.href = this.environmentService.getRegistrationServiceBaseUrl('authorizationRegistration/logout');
    Promise.resolve('Backend logout succes');
  }

  hasValidIdToken(): boolean {
    if (this.getToken()) {
      const expiresAt = this.cookieService.get(this.TOKEN_EXPIRES_AT);
      const now = new Date();
      return !(expiresAt && parseInt(expiresAt, 10) < now.getTime());
    }
    return false;
  }

  getToken(): string {
    return this.cookieService.get(this.ID_TOKEN);
  }

  private putInStorage(securityItem: SecurityItem) {
    console.log('securityItem', securityItem);
    // @ts-ignore
    this.cookieService.set(this.EXPIRES_IN, securityItem.expires_in);
    // @ts-ignore
    this.cookieService.set(this.TOKEN_EXPIRES_AT, securityItem.getTokenExpiresAt());
    this.cookieService.set(this.ACCESS_TOKEN, securityItem.access_token);
    this.cookieService.set(this.ID_TOKEN, securityItem.id_token);
    this.cookieService.set(this.REFRESH_TOKEN, securityItem.refresh_token);
  }

  private deleteSecurityStorage() {
    this.cookieService.delete(this.EXPIRES_IN);
    this.cookieService.delete(this.TOKEN_EXPIRES_AT);
    this.cookieService.delete(this.ACCESS_TOKEN);
    this.cookieService.delete(this.ID_TOKEN);
    this.cookieService.delete(this.REFRESH_TOKEN);
  }

  getUser(): Observable<ACMUser> {
    const decodeToken = JwtHelper.decodeToken(this.cookieService.get(this.ID_TOKEN));
    if (decodeToken != null) {
      this.user = new ACMUser(
        decodeToken.sub,
        decodeToken.given_name,
        decodeToken.family_name
      );
      return of(this.user);
    } else {
      this.user = null;
      return throwError('No identity claims found');
    }
  }

  isLoggedIn(): boolean {
    return this.hasValidIdToken();
  }

  logoffAfterDelay() {
    setTimeout(() => {
      this.logoff();
    }, this.environment.automaticLogoffDelay * 1000);
  }
}
